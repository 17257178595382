import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Switch } from '@headlessui/react';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

const location = window.location.pathname;

export default function DesktopSidebar({ navigation, currentNavigation, teams }) {
    const [enabled, setEnabled] = useState(false);

    return (

        <div className="hidden xl:fixed xl:inset-y-0 xl:z-10 xl:flex xl:w-72 xl:flex-col">
            <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-black/10 px-6 ring-1 ring-white/5">
                <div className="flex h-20 shrink-0 items-center">
                    <img
                        className="h-12 w-auto"
                        src={process.env.PUBLIC_URL + 'logo.svg'}
                        alt="Your Company"
                    />
                </div>
                <nav className="flex flex-1 flex-col">
                    <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                            <ul role="list" className="-mx-2 space-y-1">
                                {navigation.map((item) => (
                                    <li key={item.name}>
                                        <a
                                            href={item.href}
                                            className={classNames(
                                                item.href === location
                                                    ? 'bg-white/5 text-white'
                                                    : 'text-gray-400 hover:text-white hover:bg-white/5',
                                                'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                            )}
                                        >
                                            <item.icon
                                                className={classNames(
                                                    item.href === location
                                                        ? 'text-green-200'
                                                        : 'text-gray-400 hover:text-white hover:bg-white/5',
                                                    'h-6 w-6 shrink-0 group-hover:text-green-200'
                                                )} aria-hidden="true" />
                                            {item.name}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </li>
                        <li>
                            <div className="text-xs font-semibold leading-6 text-gray-400">Otros enlaces</div>
                            <ul role="list" className="-mx-2 mt-2 space-y-1">
                                {teams.map((team) => (
                                    <li key={team.name}>
                                        <Link
                                            href={team.href}
                                            className={classNames(
                                                team.current
                                                    ? 'bg-white/5 text-white'
                                                    : 'text-gray-400 hover:text-white hover:bg-white/5',
                                                'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                            )}
                                        >
                                            <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-white/5 text-[0.625rem] font-medium text-gray-400 group-hover:text-white">
                                                {team.initial}
                                            </span>
                                            <span className="truncate">{team.name}</span>
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </li>
                        <li className="-mx-6 mt-auto">
                            <a
                                href="#"
                                className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-white hover:bg-white/5"
                            >
                                <img
                                    className="h-8 w-8 rounded-full bg-white/5"
                                    src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                    alt=""
                                />
                                <span className="sr-only">Your profile</span>
                                <span aria-hidden="true">Alejandro Osses</span>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    );
}
