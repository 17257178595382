import { Fragment, useState } from 'react';
import { PhotoIcon, UserCircleIcon, SquaresPlusIcon } from '@heroicons/react/24/solid';
import { Disclosure, Dialog, Transition } from '@headlessui/react';
import { MinusIcon, PlusIcon } from '@heroicons/react/20/solid';
import { PuzzlePieceIcon, DocumentTextIcon, TagIcon } from '@heroicons/react/24/outline';
import { CheckIcon, ArrowUpTrayIcon, XMarkIcon } from '@heroicons/react/24/outline'

export default function AddCourse() {

    const [secciones, setSecciones] = useState([{}]);
    const [open, setOpen] = useState(true)

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ');
    }

    return (
        <>
            <main className="lg:pr-96">

                <header className="flex items-center justify-between border-b border-white/5 px-4 py-4 sm:px-6 sm:py-6 lg:px-8">
                    <div className='flex items-center'>
                        <SquaresPlusIcon className='text-green-200 h-6 pr-2' />
                        <h1 className="text-xl font-semibold leading-7 text-white">
                            Agregar Curso</h1>
                    </div>

                </header>

                <form id='lof' className="relative space-x-4 px-4 py-4 sm:px-6 lg:px-8">
                    <div>
                        <Disclosure as="div" key={1} className="border-b border-gray-100/10 py-6">
                            {({ open }) => (
                                <>
                                    <h3 className="-my-3 flow-root">
                                        <Disclosure.Button className="flex w-full items-center justify-between py-3 text-sm text-gray-400 hover:text-gray-500">
                                            <div className='flex items-center gap-x-2'>
                                                <DocumentTextIcon className='h-6 w-6 text-green-200'></DocumentTextIcon>
                                                <span className="text-base font-medium leading-7 text-white">Datos del Curso</span>
                                            </div>
                                            <span className="ml-6 flex items-center">
                                                {open ? (
                                                    <MinusIcon className="h-5 w-5" aria-hidden="true" />
                                                ) : (
                                                    <PlusIcon className="h-5 w-5" aria-hidden="true" />
                                                )}
                                            </span>
                                        </Disclosure.Button>
                                    </h3>
                                    <Disclosure.Panel className="p-8">
                                        <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                            <div className="sm:col-span-4">
                                                <label htmlFor="username" className="block text-sm font-medium leading-6 text-green-400">
                                                    Título del curso
                                                </label>
                                                <div className="mt-2">
                                                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-500 focus-within:ring-2 focus-within:ring-inset focus-within:ring-green-400 sm:max-w-md">
                                                        <input
                                                            type="text"
                                                            name="username"
                                                            id="username"
                                                            autoComplete="username"
                                                            className="block flex-1 border-0 bg-transparent py-1.5 text-green-400 placeholder:text-gray-500 focus:ring-0 sm:text-sm sm:leading-6"
                                                            placeholder="Título del curso"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-span-full">
                                                <label htmlFor="descripcion" className="block text-sm font-medium leading-6 text-green-400">
                                                    Descripción del curso
                                                </label>
                                                <div className="mt-2">
                                                    <textarea
                                                        id="descripcion"
                                                        name="descripcion"
                                                        rows={3}
                                                        className="block w-full bg-transparent rounded-md border-0 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-500 placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-green-400 sm:text-sm sm:leading-6"
                                                        defaultValue={''}
                                                    />
                                                </div>
                                                <p className="mt-3 text-sm leading-6 text-gray-600">Máximo 40 palabras</p>
                                            </div>

                                            <div className="col-span-full">
                                                <label htmlFor="photo" className="block text-sm font-medium leading-6 text-green-400">
                                                    Miniatura
                                                </label>
                                                <div className="mt-2 flex items-center gap-x-3">
                                                    <UserCircleIcon className="h-12 w-12 text-gray-300" aria-hidden="true" />
                                                    <button
                                                        type="button"
                                                        className="rounded-md  px-2.5 py-1.5 text-sm font-semibold text-green-400 shadow-sm ring-1 ring-inset ring-gray-500 hover:bg-gray-50"
                                                    >
                                                        Examinar
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="col-span-full">
                                                <label htmlFor="cover-photo" className="block text-sm font-medium leading-6 text-green-400">
                                                    Foto de portada
                                                </label>
                                                <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-500 px-6 py-10">
                                                    <div className="text-center">
                                                        <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
                                                        <div className="mt-4 flex text-sm leading-6 text-gray-500">
                                                            <label
                                                                htmlFor="file-upload"
                                                                className="relative cursor-pointer rounded-md  font-semibold text-green-400 focus-within:outline-none focus-within:ring-2 focus-within:ring-green-400 focus-within:ring-offset-2 hover:text-green-500"
                                                            >
                                                                <span>Busca el archivo en tu equipo</span>
                                                                <input id="file-upload" name="file-upload" type="file" className="sr-only" />
                                                            </label>
                                                            <p className="pl-1">o suelta el archivo acá</p>
                                                        </div>
                                                        <p className="text-xs leading-5 text-gray-600">PNG, JPG, hasta 10MB</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Disclosure.Panel>
                                </>
                            )}
                        </Disclosure>

                        <Disclosure as="div" key={2} className="border-b border-gray-100/10 py-6">
                            {({ open }) => (
                                <>
                                    <h3 className="-my-3 flow-root">
                                        <Disclosure.Button className="flex w-full items-center justify-between py-3 text-sm text-gray-400 hover:text-gray-500">
                                            <div className='flex items-center gap-x-2'>
                                                <PuzzlePieceIcon className='h-6 w-6 text-green-200'></PuzzlePieceIcon>
                                                <span className="text-base font-medium leading-7 text-white">Descripción de Habilidades</span>
                                            </div>
                                            <span className="ml-6 flex items-center">
                                                {open ? (
                                                    <MinusIcon className="h-5 w-5" aria-hidden="true" />
                                                ) : (
                                                    <PlusIcon className="h-5 w-5" aria-hidden="true" />
                                                )}
                                            </span>
                                        </Disclosure.Button>
                                    </h3>
                                    <Disclosure.Panel className="p-8">
                                        {secciones.map((seccion, index) => (
                                            <div key={seccion.index} className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

                                                <div className='col-span-full flex justify-between items-end py-4 border-y border-gray-100/10'>
                                                    <h2 className='text-base font-semibold leading-7 text-gray-500'>Habilidad {index + 1}</h2>
                                                    <div className="flex items-center justify-end gap-x-2">
                                                        <button
                                                            type="button"
                                                            onClick={() => setSecciones([...secciones, {}])}
                                                            className="rounded-full px-1.5 py-1.5 text-sm font-semibold text-green-400 ring-1 ring-inset ring-gray-500 hover:text-white hover:bg-green-400 hover:hover:ring-green-400"
                                                        >
                                                            <PlusIcon className="h-5 w-5" aria-hidden="true" />
                                                        </button>
                                                        {index > 0 && (
                                                            <button
                                                                type="button"
                                                                onClick={() => setSecciones(secciones.filter((_, i) => i !== index))}
                                                                className="rounded-full px-1.5 py-1.5 text-sm font-semibold text-red-400 ring-1 ring-inset ring-gray-500 hover:text-white hover:bg-red-400 hover:hover:ring-red-400"
                                                            >
                                                                <MinusIcon className="h-5 w-5" aria-hidden="true" />
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="sm:col-span-3">
                                                    <label htmlFor="skillTitle" className="block text-sm font-medium leading-6 text-green-400">
                                                        Habilidad adquirida
                                                    </label>
                                                    <div className="mt-2">
                                                        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-500 focus-within:ring-2 focus-within:ring-inset focus-within:ring-green-400 sm:max-w-md">
                                                            <input
                                                                type="text"
                                                                name="skillTitle"
                                                                id="skillTitle"
                                                                autoComplete="skillTitle"
                                                                className="block flex-1 border-0 bg-transparent py-1.5 text-green-400 placeholder:text-gray-500 focus:ring-0 sm:text-sm sm:leading-6"
                                                                placeholder="Habilidad adquirida"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-span-full mb-4">
                                                    <label htmlFor="descripcion" className="block text-sm font-medium leading-6 text-green-400">
                                                        Descripción de habilidad
                                                    </label>
                                                    <div className="mt-2">
                                                        <textarea
                                                            id="descripcion"
                                                            name="descripcion"
                                                            rows={3}
                                                            className="block w-full bg-transparent rounded-md border-0 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-500 placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-green-400 sm:text-sm sm:leading-6"
                                                            defaultValue={''}
                                                        />
                                                    </div>
                                                    <p className="mt-3 text-sm leading-6 text-gray-600">Máximo 40 palabras</p>
                                                </div>
                                            </div>
                                        ))}
                                    </Disclosure.Panel>
                                </>
                            )}
                        </Disclosure>

                        <Disclosure as="div" key={'classification'} className="border-b border-gray-100/10 py-6">
                            {({ open }) => (
                                <>
                                    <h3 className="-my-3 flow-root">
                                        <Disclosure.Button className="flex w-full items-center justify-between py-3 text-sm text-gray-400 hover:text-gray-500">
                                            <div className='flex items-center gap-x-2'>
                                                <TagIcon className='h-6 w-6 text-green-200'></TagIcon>
                                                <span className="text-base font-medium leading-7 text-white">Clasificación del Curso</span>
                                            </div>
                                            <span className="ml-6 flex items-center">
                                                {open ? (
                                                    <MinusIcon className="h-5 w-5" aria-hidden="true" />
                                                ) : (
                                                    <PlusIcon className="h-5 w-5" aria-hidden="true" />
                                                )}
                                            </span>
                                        </Disclosure.Button>
                                    </h3>
                                    <Disclosure.Panel className="p-8">
                                        <div className="space-y-10">
                                            <fieldset>
                                                <legend className="text-sm font-semibold leading-6 text-green-400">Categorias</legend>
                                                <div className="mt-6 space-y-6">
                                                    <div className="relative flex gap-x-3">
                                                        <div className="flex h-6 items-center">
                                                            <input
                                                                id="comments"
                                                                name="comments"
                                                                type="checkbox"
                                                                className="h-4 w-4 bg-transparent rounded border-gray-500 text-green-400 focus:ring-green-400"
                                                            />
                                                        </div>
                                                        <div className="text-sm leading-6">
                                                            <label htmlFor="comments" className="font-medium text-green-400">
                                                                Ventas
                                                            </label>
                                                            <p className="text-gray-500">Conocimientos en ventas generales</p>
                                                        </div>
                                                    </div>
                                                    <div className="relative flex gap-x-3">
                                                        <div className="flex h-6 items-center">
                                                            <input
                                                                id="candidates"
                                                                name="candidates"
                                                                type="checkbox"
                                                                className="h-4 w-4 bg-transparent rounded border-gray-500 text-green-400 focus:ring-green-400"
                                                            />
                                                        </div>
                                                        <div className="text-sm leading-6">
                                                            <label htmlFor="candidates" className="font-medium text-green-400">
                                                                Venta Telefónica
                                                            </label>
                                                            <p className="text-gray-500">Enfocado en la venta telefónica</p>
                                                        </div>
                                                    </div>
                                                    <div className="relative flex gap-x-3">
                                                        <div className="flex h-6 items-center">
                                                            <input
                                                                id="offers"
                                                                name="offers"
                                                                type="checkbox"
                                                                className="h-4 w-4 bg-transparent rounded border-gray-500 text-green-400 focus:ring-green-400"
                                                            />
                                                        </div>
                                                        <div className="text-sm leading-6">
                                                            <label htmlFor="offers" className="font-medium text-green-400">
                                                                Seguro de Vida
                                                            </label>
                                                            <p className="text-gray-500">Enfocado en Ventas de seguro de Vida</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </fieldset>
                                            <fieldset>
                                                <legend className="text-sm font-semibold leading-6 text-green-400">Calidad del curso</legend>
                                                <p className="mt-1 text-sm leading-6 text-gray-600">Indica la calidad de imagen del curso</p>
                                                <div className="mt-6 space-y-6">
                                                    <div className="flex items-center gap-x-3">
                                                        <input
                                                            id="push-everything"
                                                            name="push-notifications"
                                                            type="radio"
                                                            className="h-4 w-4 bg-transparent border-gray-500 text-green-400 focus:ring-green-400"
                                                        />
                                                        <label htmlFor="push-everything" className="block text-sm font-medium leading-6 text-green-400">
                                                            HD (720x480)
                                                        </label>
                                                    </div>
                                                    <div className="flex items-center gap-x-3">
                                                        <input
                                                            id="push-email"
                                                            name="push-notifications"
                                                            type="radio"
                                                            className="h-4 w-4 bg-transparent border-gray-500 text-green-400 focus:ring-green-400"
                                                        />
                                                        <label htmlFor="push-email" className="block text-sm font-medium leading-6 text-green-400">
                                                            Full HD (1920x1080)
                                                        </label>
                                                    </div>
                                                    <div className="flex items-center gap-x-3">
                                                        <input
                                                            id="push-nothing"
                                                            name="push-notifications"
                                                            type="radio"
                                                            className="h-4 w-4 bg-transparent border-gray-500 text-green-400 focus:ring-green-400"
                                                        />
                                                        <label htmlFor="push-nothing" className="block text-sm font-medium leading-6 text-green-400">
                                                            4K (3840x2160)
                                                        </label>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </div>
                                    </Disclosure.Panel>
                                </>
                            )}
                        </Disclosure>
                    </div>

                    <div className="mt-6 flex items-center justify-end gap-x-6">
                        <button type="button" className="text-sm font-semibold leading-6 text-green-400">
                            Cancelar
                        </button>
                        <button
                            type="submit"
                            className="rounded-md bg-green-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-400"
                        >
                            Guardar
                        </button>
                    </div>
                </form>
                <Transition.Root show={open} as={Fragment}>
                    <Dialog as="div" className="relative z-20" onClose={setOpen}>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                >
                                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-gray-900 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl">
                                        <div className='p-5 border-b border-white/5 flex justify-between'>
                                            <h2 className='text-white text-xl font-bold'>Subir Videos</h2>
                                            <button
                                                type="button"
                                                onClick={() => setOpen(false)}
                                            >
                                                <XMarkIcon className='h-6 w-6 text-gray-300' />
                                            </button>
                                        </div>
                                        <div className='px-5 py-5 '>
                                            <div className="mx-auto flex h-24 w-24 items-center justify-center rounded-full bg-black/20">
                                                <ArrowUpTrayIcon className="h-12 w-12 text-gray-500" aria-hidden="true" />
                                            </div>
                                            <div className="mt-3 text-center sm:mt-5">
                                                <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-white">
                                                    Arrastra y suelta archivos de video para subirlos
                                                </Dialog.Title>
                                                <div className="mt-2">
                                                    <p className="text-md text-gray-300">
                                                        Por favor, asegúrate de nombrar cada archivo de video con el título del capítulo y agregar el sufijo "@capX.mp4" para indicar el número de capítulo.<br /><br /> <span className='font-bold text-green-400'>Ej: introduccion_a_las_ventas@cap1.mp4</span><br /><br /> Los archivos deben estar en formato MP4 y no exceder los 20 MB de tamaño.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-5 sm:mt-6 flex justify-center px-5 py-5">
                                            <button
                                                type="button"
                                                className="inline-flex justify-center rounded-md bg-green-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-400/70 ease-in duration-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-400"
                                                onClick={() => setOpen(false)}
                                            >
                                                {/*este boton muestra los elementos con id #videos*/}
                                                Seleccionar Archivos
                                            </button>
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </Dialog>
                </Transition.Root>
            </main>
            <aside className="bg-black/10 lg:fixed lg:bottom-0 lg:right-0 lg:top-16 lg:w-96 lg:overflow-y-auto lg:border-l lg:border-white/5 p-6">
                <div>

                    <button
                        type="button"
                        className="w-full inline-flex justify-center rounded-md bg-green-400 px-3 py-2 mb-5 text-sm font-semibold text-white shadow-sm hover:bg-green-400/70 ease-in duration-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-400"
                        onClick={() => setOpen(true)}
                    >
                        Seleccionar Archivos
                    </button>
                </div>
                <div className={open ? 'hidden' : 'block'}>

                    <iframe className='w-full aspect-video' src="https://www.youtube.com/embed/djV11Xbc914" title="a-ha - Take On Me (Video Oficial)" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                    <div className='bg-white/5 p-4'>
                        <div className='mb-2'>
                            <p className='text-gray-300 text-sm'>Titulo del Video:</p>
                            <h4 className='text-white text-lg'>introducción a las Ventas</h4>
                        </div>
                        <div className='mb-2'>
                            <p className='text-gray-300 text-sm'>Capitulo</p>
                            <h4 className='text-white text-lg'>1</h4>
                        </div>
                        <div className='mb-2'>
                            <div className='flex justify-between'>
                                <p className='text-gray-300 text-sm mb-2'>Subiendo Video</p>
                                <p className='text-white text-md mb-2'>36%</p>

                            </div>
                            <div className="flex flex-auto rounded-full bg-slate-100">
                                <div className="h-2 w-1/3 flex-none rounded-l-full rounded-r-[1px] bg-green-400"></div>
                            </div>
                        </div>
                    </div>

                </div>
            </aside>
        </>
    )
}
