import React from 'react';
import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronRightIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import {
    SquaresPlusIcon,
    PlusIcon
} from '@heroicons/react/24/outline'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Courses({ courses, environments, statuses }) {

    return (
        <main className="lg:pr-96 relative flex-grow">
            <header className="flex items-center justify-between border-b border-white/5 px-4 py-4 sm:px-6 sm:py-6 lg:px-8">
                <div className='flex items-center'>
                    <SquaresPlusIcon className='text-green-200 h-6 pr-2' />
                    <h1 className="text-base font-semibold leading-7 text-white">
                        Cursos</h1>
                </div>

                <Menu as="div" className="relative">
                    <Menu.Button className="flex items-center gap-x-1 text-sm font-medium leading-6 text-white">
                        Filtrar por
                        <ChevronUpDownIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                    </Menu.Button>
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items className="absolute right-0 z-10 mt-2.5 w-40 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                            <Menu.Item>
                                {({ active }) => (
                                    <a
                                        href="#"
                                        className={classNames(
                                            active ? 'bg-gray-50' : '',
                                            'block px-3 py-1 text-sm leading-6 text-gray-900'
                                        )}
                                    >
                                        Name
                                    </a>
                                )}
                            </Menu.Item>
                            <Menu.Item>
                                {({ active }) => (
                                    <a
                                        href="#"
                                        className={classNames(
                                            active ? 'bg-gray-50' : '',
                                            'block px-3 py-1 text-sm leading-6 text-gray-900'
                                        )}
                                    >
                                        Date updated
                                    </a>
                                )}
                            </Menu.Item>
                            <Menu.Item>
                                {({ active }) => (
                                    <a
                                        href="#"
                                        className={classNames(
                                            active ? 'bg-gray-50' : '',
                                            'block px-3 py-1 text-sm leading-6 text-gray-900'
                                        )}
                                    >
                                        Environment
                                    </a>
                                )}
                            </Menu.Item>
                        </Menu.Items>
                    </Transition>
                </Menu>
            </header>

            <ul role="list" className="divide-y divide-white/5">
                {courses.map((course) => (
                    <li key={course.id} className="relative flex items-center space-x-4 px-4 py-4 sm:px-6 lg:px-8">
                        <div className="min-w-0 flex-auto">
                            <div className="flex items-center gap-x-3">
                                <img
                                    src={course.imageSrc}
                                    alt={course.imageAlt}
                                    className="h-16 w-auto flex-none rounded-md"
                                />
                                <div className='block'>

                                    <div className='flex items-center space-x-2'>

                                        <div className={classNames(statuses[course.status], 'flex-none rounded-full p-1')}>
                                            <div className="h-2 w-2 rounded-full bg-current" />
                                        </div>
                                        <h2 className="min-w-0 text-sm font-semibold leading-6 text-white">
                                            <a href={course.href} className="flex gap-x-2 items-center">
                                                <span className="truncate">{course.title}</span>
                                                <span className="text-gray-400">/</span>
                                                <div className='flex items-center justify-center h-4 rounded-[4px] ring-1 ring-white px-2'>
                                                    <span className="whitespace-nowrap text-xs">{course.quality}</span>
                                                </div>
                                                <span className="absolute inset-0" />
                                            </a>
                                        </h2>
                                    </div>

                                    <p className="whitespace-nowrap pl-6 text-green-200 text-xs">{course.chapter} Capítulos</p>
                                    <div className="flex items-center gap-x-2.5 text-xs leading-5 pl-6 text-gray-500">
                                        {course.information.map((info, index) => (
                                            <React.Fragment key={index}>
                                                <p className="truncate">{info}</p>
                                                {index < course.information.length - 1 && (
                                                    <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 flex-none fill-gray-300">
                                                        <circle cx={1} cy={1} r={1} />
                                                    </svg>
                                                )}
                                            </React.Fragment>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {course.new && (
                            <div
                                className={classNames(
                                    environments[course.new],
                                    'rounded-full flex-none py-1 px-2 text-xs font-medium bg-red-400 text-white'
                                )}
                            >
                                Nuevo
                            </div>
                        )}
                        <ChevronRightIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                    </li>
                ))}
            </ul>

                <a href='/añadirCurso' className='flex items-center justify-center rounded-full z-10 h-12 m-10 w-12 absolute bottom-0 right-96 bg-green-400 text-white'>
                    <PlusIcon className='h-6 w-6'></PlusIcon>
                </a>
        </main>
    )
};
