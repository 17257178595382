import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Header from '../components/common/Header';
import Sidebar from '../components/common/SideBar';
import DesktopSidebar from '../components/common/DesktopSidebar';
import Home from '../pages/Home';
import Courses from '../pages/Courses';
import Course from '../pages/Course';
import AddCourse from '../pages/AddCourse';
import AsideBar from '../components/common/asideBar';
import ScrollToTopOnMount from '../components/common/ScrollToTopOnMount';

import Thumbnail1 from '../assets/images/thumbnails/1.jpg'
import Thumbnail2 from '../assets/images/thumbnails/2.jpg'
import Thumbnail3 from '../assets/images/thumbnails/3.jpg'
import Thumbnail4 from '../assets/images/thumbnails/4.jpg'

import {
  HomeIcon,
} from '@heroicons/react/24/outline'

import {
  ChartBarSquareIcon,
  Cog6ToothIcon,
  FolderIcon
} from '@heroicons/react/24/outline'

const navigation = [
  { name: 'Home', href: '/', icon: HomeIcon, current: false },
  { name: 'Cursos', href: '/cursos', icon: FolderIcon, current: false },
  { name: 'Análisis', href: '/analisis', icon: ChartBarSquareIcon, current: true },
  { name: 'Configuración', href: '/configuracion', icon: Cog6ToothIcon, current: false },
]
const teams = [
  { id: 1, name: 'Perfil', href: '#', initial: 'P', current: false },
  { id: 2, name: 'Notificaciones', href: '#', initial: 'N', current: false },
  { id: 3, name: 'Reportes', href: '#', initial: 'R', current: false },
]
const statuses = {
  desactivado: 'text-gray-500 bg-gray-100/10',
  activo: 'text-green-400 bg-green-400/10',
  error: 'text-rose-400 bg-rose-400/10',
}
const environments = {
  Preview: 'text-gray-400 bg-gray-400/10 ring-gray-400/20',
  Production: 'text-green-400 bg-green-400/10 ring-green-400/30',
}
const courses = [
  {
    "id": 1,
    "title": "Tu primera llamada",
    "status": "activo",
    "chapter": 18,
    "quality": "HD",
    "new": true,
    "href": "curso",
    "imageSrc": Thumbnail1,
    "imageAlt": "Thumbnail del Curso",
    "information": [
      "Ventas",
      "Seguro Vida",
      "V. telefónica"
    ]
  },
  {
    "id": 2,
    "title": "Tu primera llamada",
    "status": "error",
    "chapter": 18,
    "quality": "HD",
    "new": true,
    "href": "curso",
    "imageSrc": Thumbnail2,
    "imageAlt": "Thumbnail del Curso",
    "information": [
      "Ventas",
      "Seguro Vida",
      "V. telefónica"
    ]
  },
  {
    "id": 3,
    "title": "Tu primera llamada",
    "status": "activo",
    "chapter": 18,
    "quality": "HD",
    "new": false,
    "href": "curso",
    "imageSrc": Thumbnail3,
    "imageAlt": "Thumbnail del Curso",
    "information": [
      "Ventas",
      "Seguro Vida",
      "V. telefónica"
    ]
  },
  {
    "id": 4,
    "title": "Tu primera llamada",
    "status": "desactivado",
    "chapter": 18,
    "quality": "HD",
    "new": true,
    "href": "curso",
    "imageSrc": Thumbnail4,
    "imageAlt": "Thumbnail del Curso",
    "information": [
      "Ventas",
      "Seguro Vida",
      "V. telefónica"
    ]
  }
]
const activity = [
  { id: 1, type: 'Reaccionó', person: { name: 'Fernanda Naranjo' }, date: 'hace 7 días', dateTime: '2023-01-23T10:32' },
  { id: 2, type: 'Reaccionó', person: { name: 'Gustavo Garrido' }, date: 'hace 6 días', dateTime: '2023-01-23T11:03' },
  { id: 3, type: 'Reaccionó', person: { name: 'Roberto Alvarado' }, date: 'hace 6 días', dateTime: '2023-01-23T11:24' },
  {
    id: 4,
    type: 'Comento',
    person: {
      name: 'Fernanda Naranjo',
      imageUrl:
        'https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    comment: 'Excelente curso, me encanto como explican todo, muy contento!',
    date: 'hace 3 días',
    dateTime: '2023-01-23T15:56',
  },
  { id: 5, type: 'Reaccionó', person: { name: 'Alexander Saenz' }, date: 'hace 5 días', dateTime: '2023-01-24T09:12' },
  { id: 6, type: 'Reaccionó', person: { name: 'Hernán Salgado' }, date: 'hace 2 días', dateTime: '2023-01-24T09:20' },
]

export default function Layout() {
  const [currentNavigation, setCurrentNavigation] = useState('Home');
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const location = useLocation();

  // UseEffect para actualizar la ruta actual
  useEffect(() => {
    setCurrentNavigation(location.pathname);
  }, [location]);

  // Condición para mostrar u ocultar AsideBar
  const showAsideBar = currentNavigation === '/' || currentNavigation === '/cursos';

  return (
    <>
      <ScrollToTopOnMount />
      <Sidebar navigation={navigation} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} currentNavigation={currentNavigation} teams={teams} />

      <DesktopSidebar navigation={navigation} currentNavigation={currentNavigation} teams={teams} />

      <div className="xl:pl-72 h-full flex flex-col">
        <Header setSidebarOpen={setSidebarOpen} courses={courses} statuses={statuses} />

        <Routes>
          <Route path="/" element={<Home />}
            onEnter={() => setCurrentNavigation('Home')}
          />
          <Route path='/cursos' element={<Courses courses={courses} environments={environments} statuses={statuses} />} />
          <Route path='/curso' element={<Course statuses={statuses} />} />
          <Route path='/añadirCurso' element={<AddCourse />} />

        </Routes>

        {showAsideBar && <AsideBar activity={activity} />}
      </div>
    </>

  );
};