import React from 'react';
import { Fragment, useState } from 'react'
import { Combobox, Dialog, Transition } from '@headlessui/react'
import { MagnifyingGlassIcon, Bars3Icon } from '@heroicons/react/20/solid'
import { VideoCameraSlashIcon } from '@heroicons/react/24/outline'
import { ChevronRightIcon } from '@heroicons/react/20/solid'

const people = [
  {
    id: 1,
    name: 'Leslie Alexander',
    phone: '1-493-747-9031',
    email: 'lesliealexander@example.com',
    role: 'Co-Founder / CEO',
    url: 'https://example.com',
    profileUrl: '#',
    imageUrl:
      'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    id: 2,
    name: 'loslei Alexander',
    phone: '1-493-747-9031',
    email: 'lesliealexander@example.com',
    role: 'Co-Founder / CEO',
    url: 'https://example.com',
    profileUrl: '#',
    imageUrl:
      'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    id: 3,
    name: 'mensui Alexander',
    phone: '1-493-747-9031',
    email: 'lesliealexander@example.com',
    role: 'Co-Founder / CEO',
    url: 'https://example.com',
    profileUrl: '#',
    imageUrl:
      'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    id: 4,
    name: 'Chiplie Alexander',
    phone: '1-493-747-9031',
    email: 'lesliealexander@example.com',
    role: 'Co-Founder / CEO',
    url: 'https://example.com',
    profileUrl: '#',
    imageUrl:
      'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    id: 5,
    name: 'Mesoñe Alexander',
    phone: '1-493-747-9031',
    email: 'lesliealexander@example.com',
    role: 'Co-Founder / CEO',
    url: 'https://example.com',
    profileUrl: '#',
    imageUrl:
      'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  // More people...
]


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
export default function Header({ setSidebarOpen, courses, statuses }) {
  const [query, setQuery] = useState('')
  const [open, setOpen] = useState(false)

  const filteredCourses =
    query === ''
      ? []
      : courses.filter((course) => {
        return course.title.toLowerCase().includes(query.toLowerCase())
      })
  console.log(courses);

  const recent = [courses[5], courses[4], courses[2], courses[10], courses[16]]
  return (
    <div className="sticky top-0 z-10 flex h-16 shrink-0 items-center gap-x-6 border-b border-white/5 bg-gray-900 px-4 shadow-sm sm:px-6 lg:px-8">
      <button type="button" className="-m-2.5 p-2.5 text-white xl:hidden" onClick={() => setSidebarOpen(true)}>
        <span className="sr-only">Open sidebar</span>
        <Bars3Icon className="h-5 w-5" aria-hidden="true" />
      </button>

      <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
        <button className="flex flex-1" onClick={() => setOpen(true)}>
          <label htmlFor="search-field" className="sr-only">
            Buscar curso
          </label>
          <div className="relative w-full h-full flex items-center">
            <MagnifyingGlassIcon
              className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-500"
              aria-hidden="true"
            />
            <div
              id="search-field"
              className="flex items-center h-full w-full border-0 bg-transparent py-0 pl-8 pr-0 text-gray-500 focus:ring-0 sm:text-sm text-left"
            >Buscar Curso</div>
          </div>
        </button>

        <Transition.Root show={open} as={Fragment} afterLeave={() => setQuery('')} appear>
          <Dialog as="div" className="relative z-10" onClose={setOpen}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900 bg-opacity-80 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto pt-16 pr-96 pl-72">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="mx-auto transform divide-y divide-white/5 overflow-hidden bg-gray-900 shadow-2xl border border-white/5 transition-all">
                  <Combobox onChange={(course) => (window.location = course.href)}>
                    {({ activeOption }) => (
                      <>
                        <div className="relative">
                          <MagnifyingGlassIcon
                            className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-500"
                            aria-hidden="true"
                          />
                          <Combobox.Input
                            className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-100 placeholder:text-gray-500 focus:ring-0 sm:text-sm"
                            placeholder="Buscar Curso..."
                            onChange={(event) => setQuery(event.target.value)}
                          />
                        </div>

                        {query === '' || (filteredCourses && filteredCourses.length > 0) && (
                          <Combobox.Options as="div" static hold className="flex transform-gpu divide-x divide-white/5">
                            <div
                              className={classNames(
                                'max-h-96 min-w-0 flex-auto scroll-py-4 overflow-y-auto px-6 py-4',
                                activeOption && 'sm:h-96'
                              )}
                            >
                              {query === '' && (
                                <h2 className="mb-4 mt-2 text-xs font-semibold text-gray-500">Busquedas recientes</h2>
                              )}
                              <div className="-mx-2 text-sm text-gray-500">
                                {(query === '' ? recent : filteredCourses)?.map((course) => (
                                  <Combobox.Option
                                    as="div"
                                    key={course.id}
                                    value={course}
                                    className={({ active }) =>
                                      classNames(
                                        'flex cursor-default select-none items-center rounded-md p-2',
                                        active && 'bg-white/5 text-green-400'
                                      )
                                    }
                                  >
                                    {({ active }) => (
                                      <>
                                        <img src={course.imageSrc} alt="" className="h-6 w-10 flex-none rounded-sm" />
                                        <span className="ml-3 flex-auto truncate">{course.title}</span>
                                        {active && (
                                          <ChevronRightIcon
                                            className="ml-3 h-5 w-5 flex-none text-gray-100"
                                            aria-hidden="true"
                                          />
                                        )}
                                      </>
                                    )}
                                  </Combobox.Option>
                                ))}
                              </div>
                            </div>

                            {activeOption && (
                              <div className="hidden h-96 w-1/2 flex-none flex-col divide-y divide-white/5 overflow-y-auto sm:flex">
                                <div className="flex-none p-6 text-center">
                                  <img src={activeOption.imageSrc} alt="" className="mx-auto h-16 w-28 rounded-sm" />
                                  <h2 className="mt-3 font-semibold text-gray-100">{activeOption.title}</h2>
                                  <div className='flex items-center justify-center'>

                                    <div className={classNames(statuses[activeOption.status], 'flex-none rounded-full p-1')}>
                                      <div className="h-2 w-2 rounded-full bg-current" />
                                    </div>
                                    <p className="pl-2 text-sm leading-6 text-gray-500">{activeOption.status}</p>
                                  </div>
                                </div>
                                <div className="flex flex-auto flex-col justify-between p-6">
                                  <dl className="grid grid-cols-1 gap-x-6 gap-y-3 text-sm text-gray-500">
                                    <dt className="col-end-1 font-semibold text-gray-100">Cápitulos</dt>
                                    <dd>{activeOption.chapter}</dd>
                                    <dt className="col-end-1 font-semibold text-gray-100">Categorías</dt>
                                    <dd className="truncate">
                                      <div className="flex items-center gap-x-2.5 text-xs leading-5 text-gray-500">
                                        {activeOption.information.map((info, index) => (
                                          <React.Fragment key={index}>
                                            <p className="truncate">{info}</p>
                                            {index < activeOption.information.length - 1 && (
                                              <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 flex-none fill-gray-300">
                                                <circle cx={1} cy={1} r={1} />
                                              </svg>
                                            )}
                                          </React.Fragment>
                                        ))}
                                      </div>
                                    </dd>
                                    <dt className="col-end-1 font-semibold text-gray-100">Calidad</dt>
                                    <dd className="flex items-center justify-center h-4 w-8 rounded-[4px] ring-1 ring-gray-500 px-2">
                                      <span className="whitespace-nowrap text-xs">{activeOption.quality}</span>
                                    </dd>
                                  </dl>
                                  <a href='/curso'
                                    type="button"
                                    className="mt-6 w-full text-center rounded-md bg-green-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                  >
                                    Ver Curso
                                  </a>
                                </div>
                              </div>
                            )}
                          </Combobox.Options>
                        )}

                        {query !== '' && filteredCourses.length === 0 && (
                          <div className="px-6 py-14 text-center text-md sm:px-14">
                            <VideoCameraSlashIcon className="mx-auto h-6 w-6 text-green-200" aria-hidden="true" />
                            <p className="mt-4 font-semibold text-white">Curso no encontrado</p>
                            <p className="mt-2 text-gray-500">
                              No pudimos encontrar nada con ese nombre, por favor intenta con un nombre diferente.
                            </p>
                          </div>
                        )}
                      </>
                    )}
                  </Combobox>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

      </div>
    </div>
  );
};