import React from 'react';
import { Fragment } from 'react';
import { Disclosure } from '@headlessui/react';
import { StarIcon } from '@heroicons/react/20/solid';
import { PencilSquareIcon, MinusIcon, SquaresPlusIcon, PlusIcon } from '@heroicons/react/24/outline';
import Thumbnail1 from '../assets/images/modal-thumbnail/1.jpg';

const course = [
    {
        id: 30,
        title: "Técnicas de venta telefónica",
        rating: 4,
        description: "Potencia tus ventas por teléfono con efectivas técnicas de comunicación y persuación para el éxito comercial.",
        image: Thumbnail1,
        status: "activo",
        chapter: 18,
        quality: "HD",
        new: true,
        href: "#",
        information: [
            "Ventas",
            "Seguro Vida",
            "V. telefónica"
        ],
        skills: [
            {
                skillName: 'Habilidades de Venta Remota',
                skillDetail: 'Aprende estrategias efectivas para persuadir y cerrar ventas por teléfono.'
            },
            {
                skillName: 'Enfoque en el Sector de Seguros',
                skillDetail: 'Adquiere conocimientos específicos para el campo de seguros.'
            },
            {
                skillName: 'Aplicación Práctica Inmediata',
                skillDetail: 'Cada lección te brindará herramientas prácticas, permitiéndote aplicar lo aprendido de manera inmediata.'
            },
        ],
    },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Course({ statuses }) {

    return (
        <main className="lg:pr-96">
            <header className="flex items-center justify-between border-b border-white/5 px-4 py-4 sm:px-6 sm:py-6 lg:px-8">
                <div className='flex items-center'>
                    <SquaresPlusIcon className='text-green-200 h-6 pr-2' />
                    <h1 className="text-base font-semibold leading-7 text-white">
                        {course[0].title}</h1>
                </div>

                <nav aria-label="Breadcrumb">
                    <ol role="list" className="flex items-center space-x-2">
                        <li>
                            <div className="flex items-center text-sm">
                                <a href="/cursos" className="font-medium text-green-400 hover:text-green-200">
                                    Cursos
                                </a>
                                <svg
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                    className="ml-2 h-5 w-5 flex-shrink-0 text-gray-300"
                                >
                                    <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                                </svg>
                                <p className="font-medium text-gray-500">{course[0].title}</p>
                            </div>
                        </li>
                    </ol>
                </nav>
            </header>
            <div className="px-4 py-4">
                <div className="lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-8">
                    <img
                        src={course[0].image}
                        className="aspect-video w-full object-cover object-center sm:rounded-lg"
                    />

                    <div className="mt-10 px-4 sm:mt-16 sm:px-0 lg:mt-0">

                        <div className='block'>

                            <div className='flex items-center space-x-2'>

                                <div className={classNames(statuses[course[0].status], 'flex-none rounded-full p-1')}>
                                    <div className="h-2 w-2 rounded-full bg-current" />
                                </div>
                                <h2 className="min-w-0 text-sm font-semibold leading-6 text-white">
                                    <a href={course[0].href} className="flex gap-x-2 items-center">
                                        <span className="truncate">{course[0].title}</span>
                                        <span className="text-gray-400">/</span>
                                        <div className='flex items-center justify-center h-4 rounded-[4px] ring-1 ring-white px-2'>
                                            <span className="whitespace-nowrap text-xs">{course[0].quality}</span>
                                        </div>
                                    </a>
                                </h2>
                            </div>

                            <p className="whitespace-nowrap pl-6 text-green-200 text-xs">{course[0].chapter} Capítulos</p>
                            <div className="flex items-center gap-x-2.5 text-xs leading-5 pl-6 text-gray-500">
                                {course[0].information.map((info, index) => (
                                    <React.Fragment key={index}>
                                        <p className="truncate">{info}</p>
                                        {index < course[0].information.length - 1 && (
                                            <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 flex-none fill-gray-300">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                        )}
                                    </React.Fragment>
                                ))}
                            </div>

                        </div>

                        <div className="mt-6">
                            <h3 className="sr-only">Description</h3>

                            <div
                                className="space-y-6 text-base text-gray-500"
                                dangerouslySetInnerHTML={{ __html: course[0].description }}
                            />
                        </div>

                        <form className="mt-6">

                            <div className="mt-10 flex">
                                <button
                                    type="submit"
                                    className="flex w-full flex-1 items-center justify-center rounded-md border border-transparent bg-green-400 px-2 py-1 text-base font-medium text-white hover:bg-green-400 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-offset-2 focus:ring-offset-gray-50 sm:w-full"
                                >
                                    <PencilSquareIcon className="h-8 w-8 pr-2" aria-hidden="true" />
                                    Editar Curso
                                </button>
                            </div>
                        </form>

                        <section aria-labelledby="details-heading" className="mt-12">
                            <h2 id="details-heading" className="sr-only">
                                Additional details
                            </h2>

                            <div className="divide-y divide-gray-100/10 border-gray-100/10 border-y">
                                {course[0].skills.map((skill) => (
                                    <Disclosure as="div" key={skill.skillName}>
                                        {({ open }) => (
                                            <>
                                                <h3>
                                                    <Disclosure.Button className="group relative flex w-full items-center justify-between py-6 text-left">
                                                        <span
                                                            className={classNames(open ? 'text-green-400' : 'text-white', 'text-sm font-medium')}
                                                        >
                                                            {skill.skillName}
                                                        </span>
                                                        <span className="ml-6 flex items-center">
                                                            {open ? (
                                                                <MinusIcon
                                                                    className="block h-6 w-6 text-green-400 group-hover:text-green-400"
                                                                    aria-hidden="true"
                                                                />
                                                            ) : (
                                                                <PlusIcon
                                                                    className="block h-6 w-6 text-green-400 group-hover:text-gray-500"
                                                                    aria-hidden="true"
                                                                />
                                                            )}
                                                        </span>
                                                    </Disclosure.Button>
                                                </h3>
                                                <Disclosure.Panel as="div" className="prose prose-sm pb-6">
                                                    <ul role="list">
                                                        <li className='text-gray-500 font-normal' key={skill.skillDetail}>{skill.skillDetail}</li>
                                                    </ul>
                                                </Disclosure.Panel>
                                            </>
                                        )}
                                    </Disclosure>
                                ))}
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </main>
    )
}
